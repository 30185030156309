import fire from '../config/fire';

export default function () {
  try {
    const local = JSON.parse(localStorage.getItem('session')).uid;
    const from_fire = fire.auth().currentUser.uid;
    return (local === from_fire)
  } catch (err) {
    console.log('NOT logged in', err);
    return false;
  }
}
