import React, {Component} from 'react';
import fire from '../config/fire.js';
import firebase from 'firebase';

var provider = new firebase.auth.FacebookAuthProvider();
provider.addScope('public_profile');

provider.setCustomParameters({
  'display': 'popup'
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null
    }
  }

  logout() {
    fire.auth().signOut().then(function() {
      console.log('sign out');
      localStorage.removeItem("session");
    }).catch(function(error) {
      console.log('error sign out');
    });
  }

  login() {
    fire.auth().signInWithPopup(provider).then(function(result) {
      // This gives you a Facebook Access Token. You can use it to access the Facebook API.
      var token = result.credential.accessToken;
      // The signed-in user info.
      var { displayName, email, photoURL, uid} = result.user;

      localStorage.setItem("session", JSON.stringify({
        displayName, email, photoURL, uid, token,
      }));

      console.log('user', result)

      /*$http.get('https://graph.facebook.com/v2.5/me?  access_token='+token+'&fields=id,name,first_name,last_name,email')
        .success(function(jsonService){
          $scope.user.firstName= jsonService.first_name;
          $scope.user.lastName= jsonService.last_name
          $scope.user.email = jsonService.email;
        });
        */

      // ...
    }).catch(function(error) {
      console.error(error.code, error.message);
    });
  }
  componentDidMount() {
  }

  render() {
    console.log( fire.auth());
    return (
      <div>
        <button onClick={this.login}>Login</button>
        <button onClick={this.logout}>Logout</button>
      </div>
    );
  }
}

export default Login;
