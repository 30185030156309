import React, { Fragment, Component } from 'react';
import { Card, Row, Col, Carousel, Divider } from 'antd';
import { withRouter } from 'react-router-dom';

const { Meta, Grid } = Card;

const Product = withRouter(({title, description, status, price, img, url, history}) => {
  const to = `/rantelo7/${url}`;
  return (
    <Card
      cover={<img alt="example" src={img} onClick={() => history.push(to)} />}
      style={{height: 550, marginBottom: 20}}
      type='inner'
    >
      <Meta
        title={title}
        description={
          <ul className='description'>
            {description.split('\n').map((e, i) => <li key={`desc-${i}`}>{e}</li>)}
            <li key={`price-${to}`}><Status s={status}>{price}</Status></li>
          </ul>
        }
      />

    </Card>

  );
});

Product.defaultProps = {
  title: 'Card title',
  description: 'This is the description',
  status: false,
  url: '#',
  img: 'https://avatars3.githubusercontent.com/u/5539993?s=400&u=ad0e8974a501cac71010154e0625cb9bf014e5d5&v=4',
}

const Products = ({products}) => {

  const cards = Object.keys(products).map(e => {
    const {name, description, status, urls, price} = products[e];
    return (
      <Col xs={{span: 24}} md={{span: 8}} key={e}>
        <Product
          description={description}
          img={urls[0]}
          price={price}
          status={status}
          title={name}
          url={e}
        />
      </Col>
    )}
  );

  const rows = [];
  for (let i=0; i < cards.length; i+=3) {
    let temparray = cards.slice(i,i+3);
    rows.push(
      <Row gutter={30} key={`row-${i}`} >
        {temparray}
      </Row>
    )
  }

  return <div>{rows}</div>
}

const Status = ({s, children}) => (
  <div className={`status ${(s) ? "sold" : "onsale"}`}>
    {children} MXN
  </div>
);
const CardDetailDescription = ({ title, description, price, status }) => (
  <Card title={title}>
    <div style={{padding: '30px 0'}}>
      <ul className='description'>
        {description.split('\n').map((e, i) => <li key={`desc-${i}`}>{e}</li>)}
      </ul>
      <Divider orientation="left" style={{color: "gray"}}>{" · · · "}</Divider>
      <Row>
        <Col md={12}>
          <Status s={status}>{price}</Status>
        </Col>
        <Col md={12}>
          <p style={{justify: "left"}}>
          Si estas interesado en comprar, enviame un mensaje directo por Facebook o dejame un comentario en la sección inferior ¡y yo te envío un inbox!
          </p>
        </Col>
      </Row>
    </div>
  </Card>
);

const gridStyle = {
  width: '100%',
  textAlign: 'center',
};

class CardDetail extends Component {
  componentDidMount() {
    window.FB.XFBML.parse();
  }

  render() {
    const {name, price, status, description, urls } = this.props.product;
    const {p} = this.props;

    return (
      <Fragment>
        <Row justify='center' gutter={20}>
          <h1 style={{marginLeft: 30}}>{name}</h1>
          <Col md={{span: 12}} xs={{span: 24}}>
            <Grid style={gridStyle}>
              <Carousel autoplay >
                { urls.map((e, i) => <img key={`detail-${i}`} src={e} alt={`imagen ${name}`} />) }
              </Carousel>
            </Grid>
          </Col>
          <Col md={{span: 12}} xs={{span: 24}}>
            <Grid style={gridStyle}>
              <CardDetailDescription
                price={price}
                status={status}
                title={name}
                description={description}
              />
            </Grid>
          </Col>
        </Row>

        <Row gutter={20} justify='center' style={{margin: "20px 0"}}>
          <Col md={12} >
            <Card title='¿Alguna pregunta?'>
              <div className="fb-comments" data-href={`https://developers.facebook.com/docs/plugins/comments#${p}`}></div>
            </Card>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const Loading = props => (
  <Row gutter={30} stype={{margin: 20}}>
    <Col xs={{span: 24}} md={{span: 8}} >
      <Card loading={true}>
        <Meta
          title="Card title"
          description="This is the description"
        />
      </Card>
    </Col>
    <Col xs={{span: 24}} md={{span: 8}} >
      <Card loading={true}>
        <Meta
          title="Card title"
          description="This is the description"
        />
      </Card>
    </Col>
    <Col xs={{span: 24}} md={{span: 8}} >
      <Card loading={true}>
        <Meta
          title="Card title"
          description="This is the description"
        />
      </Card>
    </Col>
  </Row>
)

export { Products, Product, Loading, CardDetail };
