import React from 'react';
import { Row, Col, Card, Icon } from 'antd';

const Bio = props => (
  <div>
    <Row>
      <Col md={24}>
        <Card style={{margin: "30px 0"}}>
        <div id="circle-shape-example">
          <img
            src='https://avatars3.githubusercontent.com/u/5539993?s=460&v=4'
            className='curve'
            alt='Foto Roberto Antelo'
          />

        <h1>
          <strong>¡Venta de Garage!</strong>
          {"  "}
          <Icon type="shop" theme="twoTone" />
          {"  "}
          <span style={{fontSize: '0.7em'}}> Roberto Antelo</span>
          {"  "}
          <Icon type="shop" theme="twoTone" />
        </h1>
          <p>
            Mi aventura por México esta llegando a su fin. No me queda más que agradecer a este hermoso país por las amistades que formé y todas las oportunidades que me dió. <br />
Estoy muy contento de iniciar esta nueva etapa en nuevos horizontes. No me puedo llevar todo lo que tengo 😝, por lo que estoy organizando esta venta de garage en donde encontrarás un par de cosas que tengo a la venta y con suerte otro par que ya vendí.<br />
 Sentite libre de ver lo que te guste y dejar un comentario si te interesa comprar algo. Nos ponemos de acuerdo por inbox para la entrega y el depósito. ¡Gracias!
          </p>
        </div>
        </Card>
      </Col>
    </Row>
  </div>
)

export default Bio;
