import React, {Component} from 'react';

import { CardDetail, Loading } from './Product';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import fire from "../config/fire";

const NOT_FOUND = 'NOT_FOUND';

class Detail extends Component {
  constructor(props) {
    super(props);

    const p = props.match.params.product;
    this.state = {};

    let db_ref = fire.database().ref(`rantelo7/${p}`);
    db_ref.once('value')
      .then(snapshot => {
        this.setState({ product: (snapshot.val()) ? snapshot.val() : NOT_FOUND })
      })
      .catch(e => { this.setState({ product: NOT_FOUND }) });
  }

  render() {
    const { product } = this.state;
    let detail = (product) ?
      (
        (product === NOT_FOUND) ?
        'nachos' : <CardDetail product={product} p={this.props.match.params.product} />
      ) : <Loading />;

    return (
      <div>
        <Link to='/rantelo7'>
        <Button type="primary" icon="left" size='small' style={{margin: '20px'}} > Volver a la tienda de garage</Button>
        </Link>

        {detail}
      </div>
    )
  }
}

Detail.defaultProps = {
  title: 'Card title',
  description: 'This is the description',
  status: false,
  url: '#',
  img: 'https://avatars3.githubusercontent.com/u/5539993?s=400&u=ad0e8974a501cac71010154e0625cb9bf014e5d5&v=4',
}

export default Detail;
