// Vendor
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import { Icon } from 'antd';

// Components
import Login from './components/Login';
import Garage from './components/Garage';
import NewProduct from './components/NewProduct';
import NoMatch from './components/NoMatch';
import Detail from './components/Detail';

// Styles
import './App.css';
import 'antd/dist/antd.css';

class App extends Component {
  render() {
    return (
      <Router>
        <div className="app">
          <div className='wrapper maxWidthContainer'>
            <Switch>
              <Route path="/" exact component={() => <Redirect to='/rantelo7' />} />
              <Route path="/super/hidden/login/" component={Login} />
              <Route path="/super/hidden/new_product/" component={NewProduct} />
              <Route path="/rantelo7/:product" component={Detail} />
              <Route path="/rantelo7" component={Garage} />
              <Route path="*" component={NoMatch} />
            </Switch>
          </div>
          <footer>
            Migarage.shop <Icon type="copyright" theme="outlined" />2018 by @rantelo
          </footer>
        </div>
      </Router>
    );
  }
}

export default App;
