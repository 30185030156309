import React, { Component }from 'react';

import { Products, Loading } from './Product';
import Bio from './Bio';

import fire from "../config/fire";

export default class Garage extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    let db_ref = fire.database().ref(`rantelo7`);
    db_ref.once('value').then(snapshot => {
      this.setState({
        products: snapshot.val()
      });
    });
  }

  render() {
    const { products } = this.state;
    let grid = (products) ?
      <Products products={products} /> :
      <Loading />;

    return (
      <div>
        <Bio />
        {grid}
      </div>
    );
  }
}

