import React from "react";
import {
  Button, Form, Icon, Input,
  InputNumber, Switch, Upload,
} from "antd";

import fire from "../config/fire";
import verifyAuth from "../helpers/verifyAuth";

const FormItem = Form.Item;

const CollectionCreateForm = Form.create()(
  class extends React.Component {
    constructor() {
      super();
      this.state = {
        fileList: [],
        uploaded: []
      }
      this.onRemove = this.onRemove.bind(this);
      this.beforeUpload = this.beforeUpload.bind(this);
      this.submit = this.submit.bind(this);
    }

    beforeUpload(file) {
      this.setState(({ fileList }) => ({
        fileList: [...fileList, file],
      }));
      return false;
    }

    onRemove(file) {
      this.setState(({ fileList }) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        return {
          fileList: newFileList,
        };
      });
    }

    submit() {
      let fields = this.props.form.getFieldsValue();
      const _this = this;
      if (verifyAuth()) {
        const user = JSON.parse(localStorage.getItem("session"));
        let product = fields.name.split(" ").join("_");
        this.state.fileList.forEach(file => {
          let storage_ref = fire.storage().ref(`${user.uid}/${product}/${file.name}`);
          storage_ref.put(file).then(function(snapshot) {
            _this.setState({ uploaded: [..._this.state.uploaded, snapshot] })
            console.log("uploaded", snapshot);

          });
        })
      }

      console.log(this.props.form.getFieldsValue());
    }

    normFile(e) {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    }

    componentDidUpdate() {
      const { fileList, uploaded } = this.state;

      if ( fileList.length > 0 && fileList.length === uploaded.length) {

        let fields = this.props.form.getFieldsValue();
        let product = fields.name.split(" ").join("_");

        let db_ref = fire.database().ref(`rantelo7/${product}`);
        const urls = uploaded.map(e => e.ref.getDownloadURL());
        Promise.all(urls).then(e => {
          const up = Object.assign({}, fields);
          up.urls = e;
          up.status = (up.status === undefined) ? true : up.status;
          delete up.files_to_upload;
          db_ref.set(up, (err) => {
            if (err) {
              console.log('write failed', err);
            } else {
              console.log('Inserted into DB. Move on');
            }
          });
        })
      }
    }

    render() {
      const formItemLayout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
      };
      const buttonItemLayout = {
        wrapperCol: { span: 14, offset: 4 },
      };
      const {getFieldDecorator} = this.props.form;

      return (
        <div>
          <Form layout="horizontal" >
            <FormItem label="Name" {...formItemLayout} >
              {getFieldDecorator("name", {})(
                <Input placeholder="Product Name" />
              )}
            </FormItem>
            <FormItem label="Description" {...formItemLayout} >
              {getFieldDecorator("description", {})(
                <Input.TextArea placeholder="Description..." autosize={{ minRows: 2, maxRows: 6 }} />
              )}
            </FormItem>
            <FormItem label="Price" {...formItemLayout} >
              {getFieldDecorator("price", {})(
                <InputNumber placeholder="0" />
              )}
              <span className="ant-form-text"> MXN</span>
            </FormItem>
            <FormItem label="Status" {...formItemLayout}>
              {getFieldDecorator("status", {})(
                <Switch
                  checkedChildren="sold" unCheckedChildren="on sale"
                  defaultChecked
                />
              )}
            </FormItem>
            <FormItem label="Files" {...formItemLayout} >
              {getFieldDecorator("files_to_upload",{
                valuePropName: 'fileList',
                getValueFromEvent: this.normFile,
              })(
                <Upload
                  onRemove={this.onRemove}
                  beforeUpload={this.beforeUpload}
                  listType="picture"
                >
                  <Button>
                    <Icon type="upload" /> Select File
                  </Button>
                </Upload>
              )}
            </FormItem>

            <FormItem {...buttonItemLayout}>
              <Button type="primary" onClick={this.submit} >
                Submit
              </Button>
            </FormItem>

          </Form>
        </div>
      );
    }
  }
)

export default CollectionCreateForm;
