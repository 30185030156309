import React from 'react';
import {Link} from 'react-router-dom';
import {Button,Card, Divider} from 'antd'

const NoMatch = props => (
  <div>
        <Card
          style={{width: 450, margin: "200px auto"}}
          cover={<img src="https://bashooka.com/wp-content/uploads/2015/10/404-errrrr-page-11.jpg" alt="404 page not found" />}
        >
          <Divider />
          <strong>¿A dónde va joven?</strong> Aquí ya no hay página...
          {
            <Link to='/rantelo7'>
              <Button type="default" icon="left" size='small' style={{margin: '20px'}} >Volver a la venta de garage</Button>
            </Link>
          }
        </Card>
  </div>
);

export default NoMatch;
