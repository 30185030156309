import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyC34vxzoO8A5SqWKY2CYKxzm07FkwMk4EY",
  authDomain: "migarage-6b701.firebaseapp.com",
  databaseURL: "https://migarage-6b701.firebaseio.com",
  projectId: "migarage-6b701",
  storageBucket: "migarage-6b701.appspot.com",
  messagingSenderId: "1064325420347"
};
const fire = firebase.initializeApp(config);
export default fire;

